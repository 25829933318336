// ---------------------------------------------
// ======---------------------------------======
// Structure
// ======---------------------------------======
// ---------------------------------------------
footer {
  height: 100%;
  // flex-shrink: 0;
}

.main {
  width: 90%;
  height: auto;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  margin: 2rem 1rem;
}

.body-subpage {
  color: $ltgrey;

  background: $navy;
}

.content {
  width: 80%;

  flex-grow: 1;

  margin: 2rem auto;

  h1, h2, h3, h4{
    margin: 1rem 0 0.5rem 0;
    
    font-family: $main;
    color: $turquoise;
  }

  h1 {
    margin: 1rem 0 0.5rem 0;

    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.75rem;
  }

}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.transition {
  animation-name: slidein;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
}

@keyframes slidein {
  from {
    overflow: hidden;
    margin-left: 100%;
    width: 300%;
  }

  to {
    overflow: hidden;
    margin-left: 0%;
    width: 100%;
  }
}

a {
  color: $turquoise;

  text-decoration: underline;

  &:visited {
    color: $turquoise;
  }

  &:hover, &:focus {
   opacity: 0.8;
   color: $coral;
  }
}