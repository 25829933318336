// ---------------------------------------------
// ======---------------------------------======
// Variables
// ======---------------------------------======
// ---------------------------------------------

// ====---------------====
// Font Family
// ====---------------====
$main: 'Archivo Black',
sans-serif;
$secondary: 'Montserrat',
sans-serif;

// ====---------------====
// Colors
// ====---------------====
$black: #333;
$navy: #1f2833;
$ltgrey: #c5c6c7;
$turquoise: #66fcf1;
$teal: #45a29e;
$coral: #ff6978;