//Search CSS
.form-group{
    width: 100%;
    
    display: flex;

    input {
        width: 90%;
        height: 2.5rem;

        padding: 1rem;

        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 0;

        font-size: 1rem;
    }

    button {
        width: 6rem;
        height: 2.5rem;
        
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border: 0;
        background-color: $turquoise;

        font-size: 1rem;
        font-weight: 600;
        color: $black;

        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }
    }
}

.error {
    display: none;

    padding: 1rem;
    margin-bottom: 1rem;

    border-radius: 0.4rem;
    background-color: $turquoise;
    color: $black;
    

    p {
        margin: 0;
        
        text-align: center;
    }

    svg {
        height: 1.75rem;
        width: 1.75rem;
        margin-right: 1rem;

        fill: $black;
    }

    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}