.social-media-list {
  display: flex;

  list-style-type: none;

  font-size: 1.8rem;

  padding-left: 0;
  margin: 0;
  margin-bottom: 1rem;

  @include tabletmax {
    order: 1;
  }

  li { 
    margin: 0 1rem;
    &:hover,&:focus {
      transform: scale(1.5);
    }
    a {
      fill: $navy;

      .icn {
        &:hover, &focus {
          fill: $coral;
        }
      }

      &:visited {
        fill: $navy;
      }
    }
  }
}

.social-media-list__coral {
  li {
      a {
      fill: $coral;
      
      .icn {
        &:hover, &:focus {
          fill: $teal;
        }
      }
      
      &:visited {
        fill: $coral;
      }
    }
  }
}