.content--movies {
  position: relative;
}

.movie-wrapper {
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  margin-top: 20px;
}

:root {
  --star-color: #45a29e;
  --star-background: #ff6978;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: 18px;
  line-height: 1;

  span {
    font-size: 12px;

    margin: auto 0;
  }
  
  &::before {
    content: '★★★★★';
    letter-spacing: 1px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


.card {
  width: 17.9rem;
  height: 27rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  color: $black;

  background-size: cover;
  background-position: center;
  border: 0.1rem solid $navy;
  
  h2 {
    max-width: 80%;

    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

    color: $black;
    font-size: 1.25rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.25;
    text-align: left;
  }

  &:hover, &:focus {
    .details { 
      display: block;
    }
  }
}

 .tagline {
  max-width: 75%;
  margin-top: 0;
  margin-bottom: 0.5rem;

  font-style: italic;
  font-size: 0.8rem;
 }

  .overview{
    font-size: 1rem;
  }

  .btn-link {
    margin-left: 0.2rem;
    
    color: $black;
    font-size: 0.8rem;
    font-weight: 600;
    font-style: italic;

    &.right-corner {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }

    &:hover, &:focus{
      color: $black;
      opacity: 0.8;
    }
  }

.details {
  width: 100%;
  height: 100%;

  display: none;

  padding: 0.75rem;

  position: absolute;
  bottom: 0;

  background-color: rgba($turquoise, 0.9);

  z-index: 1;
  
  &:hover, &:focus {
    display: block;
  }

  ul {
    display: flex;

    padding-left: 0;

    list-style-type: none;

    position: relative;

    font-size: 0.8rem;
    
    li {
      margin-right: 1rem;

      &:nth-child(1), &:nth-child(2), &:nth-child(3){
        &:after {
          content: '';

          width: 5px;
          height: 5px;
          
          position: absolute;

          margin-top: 5.5px;
          margin-left: 0.3rem;

          background-color: $navy;
          border-radius: 50%;
          
        }
      }
    }
  }
}

.score {
  width: 2rem;
  height: 2rem;

  display: flex;
  justify-content: center; 
  align-items: center;

  margin-bottom: 1rem;;

  position: absolute;
  top: 1.25rem;
  right: 0.75rem;

  background-color: $navy;

  p {
    color: $turquoise;
    font-weight: 500;

    z-index: 2;
  }

  &:before, &:after {
    content: '';

    height: 2rem;
    width: 2rem;

    position: absolute;
    top: 0;
    left: 0;
   
    background: $navy;
    z-index: 1;
  }

  &:before {
    transform: rotate(30deg);
  }

  &:after {
    transform: rotate(60deg);
  }
}

.back-top {
  height: 3.5rem;
  width: 3.5rem;

  display: none;

  position: fixed;
  right: 1rem;
  bottom: 1rem;

  padding: 0.5rem;

  color: $navy;

  background-color: $coral;
  border-radius: 0.25rem;
  text-decoration: none;

  z-index: 10;

  svg {
    height: 3rem;
    width: 3rem;

    fill:$navy;

    animation: bounce 1s infinite alternate;
  }

  &:visited {
    color: $navy;
  }
  &.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.disclaimer {
  margin-top: 4rem;
  margin-bottom: 0;
  text-align: center;
}

@keyframes bounce {
 from {
   transform: translate3d(0, 0, 0);
 }
 to {
   transform: translate3d(0, -0.2rem, 0);
 }
}