.project {
  border-bottom: 1px solid $ltgrey;
  padding-bottom: 2rem;

  &:last-of-type {
      border-bottom: none;
  }

  .list-photos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    list-style-type: none;

    padding-left: 0;

    @include desktop {
      flex-direction: row;
    }

    img {
      max-width: 25rem;
      width: 100%;
      max-height: 25rem;

      margin: 0.5rem;

      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3); 

      border-radius: 0.5rem;
    }

    li {
      &:nth-of-type(2) {
        z-index: 2;
      }

      &:nth-of-type(1) {
        margin-bottom: -2.5rem;
        margin-right: 0;
      }

      &:nth-of-type(3) {
        margin-top: -2.5rem;
        margin-left: 0;
      }
      @include desktop {
        &:nth-of-type(1) {
          margin-right: -1.25rem;
          margin-bottom: 0;
        }
        &:nth-of-type(3) {
          margin-left: -1.25rem;
          margin-top: 0;
        }
      }
    }
  }
}

