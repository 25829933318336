// ---------------------------------------------
// ======---------------------------------======
// Home page
// ======---------------------------------======
// ---------------------------------------------
.body-home {
  background-image: linear-gradient(rgba($navy, 1), rgba($turquoise, 0.75)), url('../images/home-background.jpg');
  background-size: cover;
  background-position: center;

  .footer {
    p {
      color: $navy;
    }
  }
}

.content-home {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1 0 100%;

  font-family: $main;

  

  h1 {
    margin-bottom: 0;

    font-size: 2rem;
    color: $navy;

    letter-spacing: 0.25rem;
    line-height: 2;

    @include tablet {
      font-size: 5rem;
    }
  }


  p {
    margin: 0;

    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: $secondary;
    color: $navy;

    @include tablet {
      font-size: 3rem;
    }
  }
}