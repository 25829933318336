// ---------------------------------------------
// ======---------------------------------======
// Footer
// ======---------------------------------======
// ---------------------------------------------
footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding: 1rem;

  p {
    margin: 1rem 0 0 0;

    font-weight: 500;

    @include tabletmax {
      margin: 0;
      order: 2;
    }
  }

  @include tabletmax {
    flex-direction: column;
    align-items: center;
  }
}