// ---------------------------------------------
// ======---------------------------------======
// Mixins
// ======---------------------------------======
// ---------------------------------------------

// =====-----------------------
// Size Converters
// =====-----------------------
$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels / $context}em;
}

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels / $context}rem;
}

// =====-----------------------
// Media Queries
// =====-----------------------
@mixin mobilelg {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktoplg {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin mobilelgmax {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tabletmax {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin desktopmax {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin desktoplgmax {
  @media (max-width: 1200px) {
    @content;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}