// ---------------------------------------------
// ======---------------------------------======
// Navigation
// ======---------------------------------======
// ---------------------------------------------
.main-nav {
  display: none;

  font-family: $main;
  font-size: 1rem;
  font-weight: 700;

  a,
  &:visited {
    padding: 0.25rem;

    color: $turquoise;
    text-decoration: none;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &.active {
      border-bottom: 0.25rem solid $coral;
    }
  }


  ul {
    display: flex;

    li {
      margin: 0 2rem;

      list-style: none;

      &:hover, &:focus{
        transform: translateY(0.5rem) scale(1.2);
        transition: 0.5s ease;
      }

    }
  }

  &.js-open {
    height: 100%;
    width: 100%;

    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;

    background-color: $turquoise;

    overflow: hidden;
    animation: slideDown 0.5s ease-in-out;

    ul {
      height: 100%;

      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      padding: 0;
      margin: 0;

      font-size: 2rem;
    }
    a {
      color: $navy;
      border-bottom-color: $coral;
    }
  }

  &.js-close {
    animation: slideUp 0.5s ease-in-out;
  }

  @include tablet {
    display: block;
  }
}

.hamburger-menu {
  display: block;

  margin-right: 1rem;
  margin-top: 1rem;
  border: none;
  outline: none;
  
  span {
    height: 0.25rem;
    width: 2.5rem;

    margin-bottom: 0.5rem;

    display: block;
    position: relative;

    background-color: $turquoise;

    &:after {
      content: '';

      width: 0;
      height: inherit;

      position: absolute;
      bottom: 0;
      left: 0;

      background-color: $coral;
    }

  }

  &:hover, &:focus {
    &.js-open {
      span{
        &:after {
          width: 100%;
          transition: 0.2s; 
        }
        &:nth-of-type(2){
          &:after {
           transition-delay: 0.1s; 
          } 
        }
  
        &:nth-of-type(3){
          &:after {
           transition-delay: 0.2s; 
          }
        }
      }
    }

    &.js-close {
      span {
        &:after {
          width: 0;

          background-color: $navy;
        }
      }
    }
    span{
      &:after {
        width: 100%;
        transition: 0.2s; 
      }
      &:nth-of-type(2){
        &:after {
         transition-delay: 0.1s; 
        } 
      }

      &:nth-of-type(3){
        &:after {
         transition-delay: 0.2s; 
        }
      }
    }
  }

  &.js-open {
    z-index: 2;

    span {
      background-color: $navy;

      &:nth-child(1) {
        transform: rotate(45deg);
        transition: ease-in 0.2s;
      }
      &:nth-child(2){
        display: none;
        transition: ease-in 0.2s;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translate(9px, -9px);
        transition: ease-in 0.2s;
      }

    }
  }

  &.js-close {
    span {
      background-color: $navy;

      &:nth-child(1) {
        transform: rotate(0deg);
        transition: ease-in 0.2s;
      }
      &:nth-child(2){
        display: block;
        transition: ease-in 0.2s;
      }
      &:nth-child(3) {
        transform: rotate(0deg);
        transition: ease-in 0.2s;
      }

      &:after {
        width: 0;
      }

    }
  }

  @include tablet {
    display: none;
  }
}

@keyframes slideDown {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

@keyframes slideUp {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}