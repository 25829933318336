// ---------------------------------------------
// ======---------------------------------======
// Globals
// ======---------------------------------======
// ---------------------------------------------

html, body {
  padding: 0;
  margin: 0;

  font-family: $secondary;
  font-size: 16px;
}


body {
  min-height: 100vh;

  &.js-open {
    overflow: hidden;
  }
}
* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}