// ---------------------------------------------
// ======---------------------------------======
// About
// ======---------------------------------======
// ---------------------------------------------
.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include desktop {
    flex-direction: row;
  }
}

.skills-prism {
  max-width: 100%;
  max-height: 100%;

  perspective: 300px;

  overflow: hidden;
}

.headshot {
  height: auto;
  width: 15rem;

  border-radius: 50%;
  border: 0.5rem $coral solid;

  margin: 2rem 2rem 2rem 0;

  @include desktop {
    width: 20rem;
  }
}

.skills-list {
  width: 100%;
  height: 10rem;

  display: flex;
  position: relative;

  margin-bottom: 3rem;

  transform-style: preserve-3d;
  transform: translateZ(-100px); 

  animation: spin 18s linear infinite;
  
  list-style-type: none;

  font-size: 3rem;


  li {
    position: absolute;
    width: 100%;
    height: 100%;

    margin: auto 0;

    img {
      max-width: 10rem;
    }

    text-align: center;
    &:nth-of-type(1){
      transform: rotateY(0deg) translateZ(300px);
      @include tablet {
        transform: translateZ(500px);
      }
    }
    &:nth-of-type(2){
      transform: rotateY(60deg) translateZ(300px);
      @include tablet {
        transform: rotateY(60deg) translateZ(500px);
      }
    }
    &:nth-of-type(3){
      transform: rotateY(1200deg) translateZ(300px);
      @include tablet {
        transform: rotateY(1200deg) translateZ(500px);
      }
    }
    &:nth-of-type(4){
      transform: rotateY(180deg) translateZ(300px);
      @include tablet {
        transform: rotateY(180deg) translateZ(500px);
      }
    }
    &:nth-of-type(5){
      transform: rotateY(240deg) translateZ(300px);
      @include tablet {
        transform: rotateY(240deg) translateZ(500px);
      }
    }
    &:nth-of-type(6){
      transform: rotateY(300deg) translateZ(300px);
      @include tablet {
        transform: rotateY(300deg) translateZ(500px);
      }
    }
  }

}

@keyframes spin {
  from{transform: translateZ(-535px) rotateY(0deg);}
  to{transform: translateZ(-535px) rotateY(360deg);}
}