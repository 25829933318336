// ---------------------------------------------
// ======---------------------------------======
// Icons
// ======---------------------------------======
// ---------------------------------------------
.icn-email  {
  width: em(20);
  height: em(20);
}

.icn-facebook {
  width: em(20);
  height: em(20);
}

.icn-git {
  width: em(20);
  height: em(20);
}

.icn-linkedin {
  width: em(20);
  height: em(19.995);
}