// ---------------------------------------------
// ======---------------------------------======
// Header styles
// ======---------------------------------======
// ---------------------------------------------

.header {
  min-height: 6rem; 
  display: flex;
  justify-content: space-between;

  margin: 0;
  padding: 1rem;


  button {
    background: none;
    border: 0;

    &:hover, &:focus {
      color: $coral;
      
      cursor: pointer;
    }
  }
}

.logo {
  width: 3rem;
  height: auto;

  margin-top: 0.5rem;
}